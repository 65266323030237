<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <!-- Card stats -->
    </base-header>

    <b-container fluid class="mt--7">
      <b-row>
        <b-col>
          <b-overlay :show="loading" rounded="md">
            <b-card no-body>
              <b-card-header class="border-1">
                <h3 class="mb-0">タスク：{{ task.name }}</h3>
              </b-card-header>
              <b-card-body>
                <div>
                  <h6 class="heading-small text-muted mb-4">タスク内容</h6>
                  <template v-if="task.workflow === '12'">
                    <AttendanceViewFields :showEditBtn="showEditBtn" :editor="editor" :editorTime="editor_time"
                      :openEditModal="openEditModal" :task="task" :fields="fields" :fields_value="fields_value" />

                  </template>
                  <template v-else-if="task.workflow === '13' || task.workflow === '14'">
                    <AssetsViewFields :task="task" :fields="fields" />
                  </template>
                  <template v-else>
                    <ViewFields :task="task" :fields="fields" />
                  </template>
                  <hr class="my-4">
                  <h6 class="heading-small text-muted mb-4">処理状況</h6>
                  <flow-chart :nodes="nodes">
                  </flow-chart>
                </div>
              </b-card-body>
              <b-card-footer v-if="task.status == '10'">
                <b-row>
                  <b-col cols="12" class="d-flex justify-content-between">
                    <span>
                      <b-button variant="primary" @click="approvalTaskNode">承認</b-button>
                      <b-button variant="danger" v-b-modal.modal-undo-task>差し戻す</b-button>
                    </span>
                    <span>
                      <b-button variant="dark" :to="{ name: 'tasks-approval' }">戻る</b-button>
                    </span>
                  </b-col>
                </b-row>
              </b-card-footer>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
      <b-modal ref="edit-details-modal" title="編集" size="xl" @ok="handleStatisticsModalConfirm">
        <b-container>
          <b-row>
            <b-col v-for="(field, key) in statistics" :key="key" md="2">
              <b-form-group :label="field.label" label-size="sm">
                <b-form-input v-model="field.value"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </b-modal>
      <b-modal id="modal-undo-task" title="タスクを差し戻す" @show="resetModal" @hidden="resetModal" @ok="undoTaskNode">
        <validation-observer ref="observer">
          <base-select v-if="task.workflow === '20'" label="差戻し理由" :rules="{ required: true }"
            :options="vacation_reject_reason" v-model="undoReason" />
          <base-textarea v-else label="差戻し理由" :rules="{ required: true }" v-model="undoReason" :error="error" />
        </validation-observer>
      </b-modal>
    </b-container>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { ajax, choice, common, config, constant } from '../../utils';
import Toast from '../../components/Toast';
import { helper, ViewFields } from '../common';
import { AttendanceViewFields } from '../common';
import { AssetsViewFields } from '../common';

export default {
  components: {
    ViewFields,
    AttendanceViewFields,
    AssetsViewFields,
  },
  data() {
    return {
      loading: true,
      task: {},
      attendance_details: null,
      fields: [],
      nodes: [],
      undoReason: null,
      error: null,
      fields_value: {},
      editModalOpen: false, // 控制模态框的显示状态
      editor: null,
      editor_time: null,
      statistics: {
        attendance_days_3: {
          label: '出勤日数',
          value: ''
        },
        attendance_time_3: {
          label: '出勤日数算出',
          value: ''
        },
        total_work_time_3: {
          label: '総就業時間',
          value: ''
        },
        total_early_time_3: {
          label: '普通残業時間',
          value: ''
        },
        over_time_3: {
          label: '普通残業時間算出',
          value: ''
        },
        total_night_time_3: {
          label: '深夜残業時間',
          value: ''
        },
        legal_holiday_3: {
          label: '法定休日出勤日数',
          value: ''
        },
        legal_holiday_time_3: {
          label: '法定休日出勤時間',
          value: ''
        },
        legal_hol_over_3: {
          label: '法定休日残業時間',
          value: ''
        },
        legal_hol_night_3: {
          label: '法定休日深夜残業時間',
          value: ''
        },
        compensatory_days_3: {
          label: '代休取得日数',
          value: ''
        },
        compensatory_leave_3: {
          label: '振替取得日数',
          value: ''
        },
        nonlegal_hol_3: {
          label: '法定外休日出勤日数',
          value: ''
        },
        nonlegal_hol_time_3: {
          label: '法定外休日出勤時間',
          value: ''
        },
        nonlegal_hol_over_3: {
          label: '法定外休日残業時間',
          value: ''
        },
        nonlegal_hol_night_3: {
          label: '法定外休日深夜残業時間',
          value: ''
        },
        paid_vacation_days_3: {
          label: '有休取得日数',
          value: ''
        },
        paid_vacation_time_3: {
          label: '有休時間',
          value: ''
        },
        absence_days_3: {
          label: '欠勤日数',
          value: ''
        },
        absence_calculate_3: {
          label: '欠勤日数算出',
          value: ''
        },
        absence_time_3: {
          label: '欠勤時間',
          value: ''
        },
        excess_over_3: {
          label: '超過残業時間',
          value: ''
        },
        amount_time_3: {
          label: '総合計時間',
          value: ''
        }
      },
      showEditBtn: false
    };
  },
  watch: {
    $route(to, from) {  // eslint-disable-line
      this.getTaskDetail(this.task_id);
    },
    organizationsMap(newValue) {
      if(newValue) {
        let org_full_name = newValue.get(this.task.org_id)
        this.task = {...this.task, org_full_name,}
      }     
    }
  },
  created: function () {
    this.getTaskDetail(this.task_id);

    // this.getAttendanceDeatils(this.task_id)
  },
  updated: function () {
    document.title = `${this.task.name} | ${common.getPageName(this.$route.name)}`;
  },
  computed: {
    ...mapState('account', ['user' , 'organizationsMap']),
    task_id: function () {
      return this.$route.params.task_id;
    },
    node_id: function () {
      return this.$route.params.node_id;
    },
    vacation_reject_reason: function () {
      return choice.vacation_reject_reason;
    },
  },
  methods: {
    ...mapActions('account', ['getStatistics']),
    openEditModal() {
      // 父组件的方法逻辑
      this.$refs['edit-details-modal'].show()
    },
    showUpdateDt: function (d) {
      return common.formatDate(new Date(d), 'YYYY/MM/DD hh:mm');
    },
    handleStatisticsModalConfirm(bvModalEvent) {
      bvModalEvent.preventDefault()
      common.loading()
      const params = {}
      params['year'] = this.fields_value.year
      params['month'] = this.fields_value.month
      params['org_id'] = this.task.org_id
      params['basic_attendance_day'] = this.fields_value.basic_attendance_day
      const keys = Object.keys(this.statistics)
      keys.forEach(k => {
        params[k] = this.statistics[k].value
      })
      ajax.fetchPost(common.formatStr(config.api.attendance.modify_details, this.task_id), params).then(() => {
        this.getTaskDetail(this.task_id)
      }).finally(() => {
        common.loaded()
        this.$refs['edit-details-modal'].hide()
      })
    },

    // getAttendanceDeatils: function (id) {
    //   // const vm = this;

    // },
    getTaskDetail: function (id) {

      const vm = this;
      ajax.fetchGet(common.formatStr(config.api.task.task_detail, id)).then(data => {
        vm.task = data;
      })
      .then(async () => {
        const _data = await ajax.fetchGet(common.formatStr(config.api.employee.v2_employee_detail, vm.task.employee)).catch(err => console.log(err)) || {};
        let full_org_name ="";
        if(this.organizationsMap.get) {
          full_org_name = this.organizationsMap.get(vm.task.org_id)
        }
        vm.task = {...vm.task , employee_code : _data.code || "退職済み", org_full_name: full_org_name}        
      })
      .then(async () => {
        const data = await ajax.fetchGet(common.formatStr(config.api.task.task_form, id));
        vm.fields = data;
        //勤怠申請
        if (vm.task.workflow === '12') {
          vm.fields_value = helper.get_value_from_fields(data);
          this.get_business_days(vm.fields_value.year, vm.fields_value.month )
        }
      }).then(async () => {
        const data = await ajax.fetchGet(common.formatStr(config.api.task.task_nodes, id)) || [];
        vm.nodes = data;

        if (data.length > 0) {
          const lastItem = data[data.length - 1]
          if (Array.isArray(lastItem)) {
            lastItem.forEach(item => {
              if (item.approver === this.user.me.id) {
                this.showEditBtn = true
              }
            })
          } else {
            if (lastItem && lastItem.approver === this.user.me.id) {
              this.showEditBtn = true
            }
            console.log('不是数组');
          }
        }
      }).then(async () => {
        const data = await ajax.fetchGet(common.formatStr(config.api.attendance.attendance_details, id))
        const keys = Object.keys(data)
        if (keys.length > 0) {
          this.editor = data['editor_name']
          this.editor_time = this.showUpdateDt(data['updated_dt'])
          keys.forEach(k => {
            if (this.statistics[k]) {
              this.statistics[k].value = data[k]
            }
            if (vm.fields_value[k]) {
              vm.fields_value[k] = data[k]
            }
          })
        } else {
          const keys = Object.keys(vm.fields_value)
          keys.forEach(k => {
            if (this.statistics[k]) {
              this.statistics[k].value = vm.fields_value[k]
            }
          })
        }
      })
        .finally(() => {
          vm.loading = false;
          common.loaded()
        });
    },
    approvalTaskNode: function () {
      if (confirm(constant.CONFIRM.SUBMIT_TASK_NODE)) {
        common.loading();
        const vm = this;
        //勤怠申請
        let fields_value = null;
        if (this.task.workflow === '12') {
          fields_value = this.fields_value;
        }
        ajax.fetchPut(common.formatStr(config.api.task.approval_node, this.task_id, this.node_id), {
          fields_value
        }).then(() => {
          vm.getStatistics();
          Toast.success(constant.SUCCESS.SUBMITTED);
          vm.$router.push({ name: 'tasks-approval' });
        }).catch(errors => {
          Toast.errorFromApi(errors)
        }).finally(() => {
          common.loaded();
        });
      }
    },
    resetModal() {
      this.undoReason = '';
      this.error = null;
    },
    get_business_days(year, month) {
            const vm = this;
            ajax.fetchGet(common.formatStr(config.api.task.business_days, year, month)).then(data => {
                vm.$set(vm.fields_value, 'basic_attendance_day', data.length);
            }).catch(errors => {
                helper.show_errors(errors);
            });
        },
    undoTaskNode: async function (bvModalEvt) {
      bvModalEvt.preventDefault();
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return
      }
      if (confirm(constant.CONFIRM.UNDO_TASK_NODE)) {
        common.loading();
        const vm = this;
        ajax.fetchPut(common.formatStr(config.api.task.undo_node, this.task_id, this.node_id), { reason: this.undoReason }).then(() => {
          vm.getStatistics();
          Toast.success(constant.SUCCESS.UNDO);
          vm.$router.push({ name: 'tasks-approval' });
        }).catch((errors) => {
          if (errors.comment) {
            vm.error = errors.comment;
          } else {
            Toast.errorFromApi(errors);
          }
        }).finally(() => {
          common.loaded();
        });
      }
    },
  },
}
</script>